body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h-100 {
  height: 100% !important
}
.w-100 {
  width: 100% !important
}
.m-0 {
  margin: unset !important
}

/* Notistack class overrides */
/* Move the snack container up slightly. */
.SnackbarContainer-root {
  transform: translate(0rem, -5rem);
}

/* Hide spinner buttons for fields of type "number". */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
